import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DataPropagationDto } from '@orquesta/data/data-propagation';
import { SafeAny } from '@orquesta/models/common';

export const DataPropagationActions = createActionGroup({
  source: 'Data Propagation',
  events: {
    init: props<{ payload: DataPropagationDto }>(),

    propagate: props<{ data: SafeAny[] }>(),
    propagateDone: emptyProps(),
  },
});
