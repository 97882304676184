import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { DATA_PROPAGATION_API, DATA_PROPAGATION_TOKEN } from './tokens';

@Injectable({ providedIn: 'root' })
export class DataPropagationService {
  private apiUrl = inject(DATA_PROPAGATION_API);
  private token = inject(DATA_PROPAGATION_TOKEN);
  private handler = inject(HttpBackend);
  private http = new HttpClient(this.handler);

  propagateData(logs: unknown[]) {
    return this.http.post(
      this.apiUrl,
      { logs },
      {
        params: { token: this.token },
      },
    );
  }
}
