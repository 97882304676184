import { provideEffects } from '@ngrx/effects';

import { DataPropagationActions } from './lib/data-propagation.actions';
import { DataPropagationEffects } from './lib/data-propagation.effects';

export * from './lib/data-propagation.effects';

const dataPropagationProviders = [provideEffects(DataPropagationEffects)];

export { DataPropagationActions, dataPropagationProviders };
