import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ENVIRONMENT_NAME } from '@orquesta/constants/tokens';
import { DataPropagationService } from '@orquesta/data/data-propagation';
import { RulesService } from '@orquesta/data/rules';

import { workspacesFeature } from '@orquesta/state/workspaces';
import { NzMessageService } from 'ng-zorro-antd/message';
import { combineLatest, filter, map, switchMap, tap } from 'rxjs';

import { modelsFeature } from '@orquesta/state/ai/models';
import { DataPropagationActions } from './data-propagation.actions';

@Injectable()
export class DataPropagationEffects {
  private actions = inject(Actions);
  private store = inject(Store);
  private rulesService = inject(RulesService);
  private message = inject(NzMessageService);
  private dataPropagationService = inject(DataPropagationService);
  private environment = inject(ENVIRONMENT_NAME);
  private worker!: Worker;

  private messageId?: string;

  constructor() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(
        new URL('./propagation.worker', import.meta.url),
      );
      this.worker.onmessage = ({ data }) =>
        this.store.dispatch(DataPropagationActions.propagate({ data }));
    }
  }

  init = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DataPropagationActions.init),
        concatLatestFrom(() => [
          this.store.select(workspacesFeature.selectActiveWorkspaceId),
          this.store
            .select(modelsFeature.selectAllModels)
            .pipe(
              map(
                (models) => new Map(models.map((model) => [model.id, model])),
              ),
            ),
        ]),
        switchMap(([{ payload }, workspaceId, models]) => {
          return combineLatest(
            payload.rules.map((ruleId) => this.rulesService.findOne(ruleId)),
          ).pipe(
            tap((rules) => {
              this.worker.postMessage({
                ...payload,
                rules,
                workspaceId,
                models,
                environment: this.environment,
              });
            }),
          );
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  propagate = createEffect(() => {
    return this.actions.pipe(
      ofType(DataPropagationActions.propagate),
      switchMap(({ data }) => {
        const dataCopy = [...data];
        const chunk = dataCopy.splice(0, 100);

        return this.dataPropagationService
          .propagateData(chunk)
          .pipe(
            map(() =>
              dataCopy.length === 0
                ? DataPropagationActions.propagateDone()
                : DataPropagationActions.propagate({ data: dataCopy }),
            ),
          );
      }),
    );
  });

  showLoader = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DataPropagationActions.propagate),
        filter(() => !this.messageId),
        tap(() => {
          this.messageId = this.message.loading(
            'Propagating data in workspace...',
            {
              nzDuration: 0,
            },
          ).messageId;
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  hideLoader = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DataPropagationActions.propagateDone),
        tap(() => {
          this.message.remove(this.messageId);
          this.message.success('Data propagated successfully', {
            nzDuration: 3000,
          });
          this.messageId = undefined;
        }),
      );
    },
    {
      dispatch: false,
    },
  );
}
